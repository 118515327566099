import BillMgrApi from '@/services/api/BillMgrApi';
import { formDataParser } from '@/utils/parsers/formDataParser.js';
import { hostToProvider, IS_PROD } from '@/layouts/LoginPage/mixins/currentProvider';
import qs from 'qs';

export const moduleReg = {
  state: {
    model: {},
    fields: {},
  },
  mutations: {
    SET_MODEL: (state, payload) => {
      state.model = payload;
    },
    SET_FIELDS: (state, payload) => {
      state.fields = payload;
    },
  },
  actions: {
    fetch(_ctx, func) {
      const params = {
        func,
        lang: 'ru',
        metadata: 'on',
        newface: 'on',
      };
      return BillMgrApi.get('/billmgr', { params });
    },
    send(_ctx, payload) {
      if (!payload.func) throw new Error('Missed required argument "func"');
      const params = {
        out: 'bjson',
        sok: 'ok',
        ...payload,
      };
      const formData = formDataParser(params);
      return BillMgrApi.post('/billmgr', formData);
    },
    newAuthUser({ dispatch, rootState }, payload = {}) {
      const { username, password, key } = payload;
      console.log(username, password, key);
      const defaultHost = IS_PROD
        ? hostToProvider[window.location.origin]
        : rootState.moduleApp.host;
      if (IS_PROD) localStorage.setItem('host', defaultHost);
      dispatch('moduleApp/setHost', defaultHost, { root: true });
      if (!username || (username && !password && !key))
        return Promise.reject(
          new Error("Missed required property: 'username' or 'password' or 'key'")
        );
      const params = new URLSearchParams();
      params.append('func', 'auth');
      params.append('out', 'xjson');
      params.append('username', username);
      params.append('newface', 'on');
      if (password) params.append('password', password);
      if (key) params.append('key', key); //
      console.log(params);
      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
      };
      return new Promise((resolve, reject) => {
        console.log(params, config);
        BillMgrApi.post('', params, config)
          // BillMgrApi.post('', params)
          .then(data => {
            console.log(data);
            console.log(data.doc.auth.$);
            // const { auth } = data.model;
            const auth = data.doc.auth.$;
            if (!auth) reject(new Error('Missed auth token'));
            dispatch('setToken', auth);
            // VueCookies.set('billmgrses5', auth);
            // this.$cookies.set('billmgrses5', auth);
            window.dataLayer = window.dataLayer.filter(x => !x.userId);
            // window.dataLayer.filter(x => x.userId);
            // localStorage.setItem();
            // console.log('--------setToken---------', localStorage.getItem('token'));
            // console.log(IS_PROD);
            dispatch(
              'moduleProfile/setProfileInfo',
              IS_PROD ? { func: 'desktop' } : { auth, func: 'desktop' }
            )
              // dispatch('moduleProfile/setProfileInfo', { func: 'desktop' })
              .then(data => {
                console.log(data);
                // if (/activationsend/.test(data.toLowerCase())) {
                // console.log('loginData', data);
                window.dataLayer.push({ userId: data.id });
                // console.log(window.dataLayer.filter(x => x.userId));
                //   resolve(data);
                // } else {
                dispatch('setToken', auth);
                resolve(data);
                // }
              })
              .catch(e => reject({ e, auth }));
          })
          // .then(reject => console.log('--------reject', reject))
          .catch(e => {
            console.log(e);
            reject(e);
          });
      });
    },
    authUser({ dispatch, rootState }, payload = {}) {
      const { username, password, key } = payload;
      const defaultHost = IS_PROD
        ? hostToProvider[window.location.origin]
        : rootState.moduleApp.host;
      if (IS_PROD) localStorage.setItem('host', defaultHost);
      dispatch('moduleApp/setHost', defaultHost, { root: true });
      if (!username || (username && !password && !key))
        return Promise.reject(
          new Error("Missed required property: 'username' or 'password' or 'key'")
        );
      const params = {
        func: 'auth',
        forget: 'on',
        out: 'bjson',
        ...payload,
      };
      // console.log(params);
      const formData = formDataParser(params);
      return new Promise((resolve, reject) => {
        // BillMgrApi.get('', { params })
        BillMgrApi.post('/billmgr', formData)
          .then(data => {
            // console.log(data);
            const { auth } = data.model;
            if (!auth) reject(new Error('Missed auth token'));
            dispatch('setToken', auth);
            window.dataLayer = window.dataLayer.filter(x => !x.userId);
            // window.dataLayer.filter(x => x.userId);
            // localStorage.setItem();
            // console.log('--------setToken---------', localStorage.getItem('token'));
            dispatch('moduleProfile/setProfileInfo', { auth })
              .then(data => {
                // if (/activationsend/.test(data.toLowerCase())) {
                // console.log('loginData', data);
                window.dataLayer.push({ userId: data.id });
                // console.log(window.dataLayer.filter(x => x.userId));
                //   resolve(data);
                // } else {
                dispatch('setToken', auth);
                resolve(data);
                // }
              })
              .catch(e => {
                // console.log(e);
                reject({ e, auth });
              });
          })
          // .then(reject => console.log('--------reject', reject))
          .catch(e => reject(e));
      });
    },

    totpConfirm({ dispatch }, payload) {
      const params = {
        clicked_button: 'ok',
        sok: 'ok',
        out: 'bjson',
        ...payload,
      };
      const formData = formDataParser(params);
      return new Promise((resolve, reject) => {
        BillMgrApi.post('/billmgr', formData)
          .then(data => {
            const { auth } = data;
            dispatch('setToken', auth);
            dispatch('moduleProfile/setProfileInfo', { auth });
            resolve();
          })
          .catch(e => reject(e));
      });
    },
    setToken(_, token) {
      localStorage.setItem('token', token);
    },
  },
  getters: {
    GET_USER: ({ user }) => user,
    GET_MODEL: ({ model }) => model,
    GET_FIELDS: ({ fields }) => fields,
  },
};

// https://IP-адрес:1500/billmgr?out=xml&func=auth&username=имя_пользователя&password=пароль
