export class OPStorage extends Object {
  /*************
   *
   * i - чтобы можно было отличить и добавить валидацию на сущность, если это понадобится в дальнейшем
   * _source - отвечает за это
   *
   * *****************/
  constructor(args, i) {
    // console.log(i);
    super(args);
    this.source = 'storage';
    this._init(args, i);
    // this._headers = [];
  }
  /*
   * из разных модулей опенстака прилетает разный формат даты
   *     .000000 -> не отображает нормально часовой пояс
   *     Z -> отображает нормально
   * поэтому приводим к единому виду
   *
   * */
  parseDate(args) {
    // console.log(args);
    // let date = args ? args.split('.') : null;
    let date = args;
    const newDate = new Date(args);
    // console.log(newDate);

    // console.log(new Date(args));

    let parsedDate;
    if (date) {
      parsedDate = date && date.length > 1 ? date[0] + 'Z' : date[0];
    } else parsedDate = null;
    return parsedDate ? new Date(parsedDate) : null;
  }

  _init(args, i) {
    // console.log(args);
    this._source = i;
    this.name = args.Name;
    this.count = args.KeyCount || 0;
    this.policy = args.isPublic || false;
    this.objects = args.Content || [];
    this.date = args.CreationDate;
    // this.date = this.parseDate(args.CreationDate);
    // this.date = parseDateRu(args.CreationDate);
    // console.log(this.name, this.date);
    // Object.keys(args).forEach(k => {
    //   if (args[k] === args.last_modified) {
    //     this.lastModified = this.parseDate(args[k]);
    //   } else this[k] = args[k];
    // });
  }
  set sizeStorage(obj) {
    let count;
    console.log(obj.map(x => x.Size));
    return obj.map(x => x.Size);
  }

  // set headers(headers) {
  //   this._headers = headers;
  //   Object.keys(headers).forEach(k => {
  //     if (headers[k] === headers.date) {
  //       this[k] = this.parseDate(headers[k]);
  //     } else if (headers[k] === headers['last-modified']) {
  //       this.lastModified = this.parseDate(headers[k]);
  //     } else if (k === 'x-container-bytes-used-actual') {
  //       this.bytes = +headers[k];
  //     } else if (k.includes('x-')) {
  //       this[k] = headers[k];
  //     }
  //   });
  // }
  // get headers() {
  //   return this._headers;
  // }

  updateResources(list) {
    list.forEach(i => {
      // console.log(i);
      if (this[i.intname]) this[i.intname].value = i.value;
    });
  }

  update(args) {
    console.log('updateStorages');
    // this.count = args.KeyCount || 0;
    // this.policy = args.isPublic || false;
    // this.objects = args.Content || [];
    this._init(args);
  }
}
