import { formDataParser } from '@/utils/parsers/formDataParser.js';
import BillMgrApi from '@/services/api/BillMgrApi';
import qs from 'qs';

export default {
  setProfileInfo({ dispatch, commit }, params = {}) {
    return new Promise((resolve, reject) => {
      dispatch('moduleStart/fetchAppInfo', { params }, { root: true })
        .then(data => {
          // console.log('gotcha!');
          if (
            data.ok &&
            data.ok.type === 'form' &&
            data.ok.v &&
            /activationsend/.test(data.ok.v.toLowerCase())
          )
            resolve(data.ok.v);
          // reject(data.ok.v);
          if (data.ok && data.ok.type === 'form' && data.ok.v) reject(data.ok);
          // console.log(data);
          if (data.ok && data.ok.type === 'url' && data.ok.v) {
            // console.log(data.ok);
            resolve(data.ok);
          }
          const { user, host, default_avatar, menu, project } = data;
          if (!user) throw new Error("Invalid data from 'func=desktop': user is not found");
          // console.log('----------------');
          commit('SET_PROFILE_INFO', { ...user, default_avatar, host });
          commit('moduleApp/SET_APP_HOST', host, { root: true });
          if (menu) commit('SET_ACCESS', menu);
          if (project) commit('moduleProviders/setCurrent', project, { root: true });
          resolve(user);
        })
        // .then(() => {
        // console.log('--setProfileInfo--');
        // })
        // .then(() => dispatch('moduleStart/fetchDasboardInfo', { root: true }))
        .catch(e => reject(e));
      // dispatch('moduleStart/fetchDasboardInfo', { root: true });
    });
  },

  setUserInfo({ commit }, id) {
    if (!id) throw new Error('Missed required params: "id" in fetchUserInfo');
    const params = {
      elid: id,
      func: 'user.edit',
      show_metadata: 'on',
      newface: 'on',
    };
    return new Promise((resolve, reject) => {
      BillMgrApi.get('/billmgr', { params })
        .then(data => {
          commit('SET_PROFILE_INFO', data.model);
          commit('UPDATE_PROFILE_INFO', { prop: 'editModel', value: data.model });
          if (data.fields) commit('UPDATE_PROFILE_INFO', { prop: 'fields', value: data.fields });
          if (data.slist) commit('UPDATE_PROFILE_INFO', { prop: 'slist', value: data.slist });
          resolve(data);
        })
        .catch(e => reject(e));
    });
  },
  setPartnersLink({ commit }) {
    const params = {
      func: 'affiliate.client',
      show_metadata: 'on',
      newface: 'on',
    };
    return new Promise((resolve, reject) => {
      BillMgrApi.get('/billmgr', { params })
        .then(data => {
          // commit('SET_PROFILE_INFO', { partners: data.fields });
          commit('SET_PARTNERS', data.fields);
          commit('SET_RESELLER', data.slist);
          resolve(data);
        })
        .catch(e => reject(e));
    });
  },
  getReferalsLink({ commit }) {
    const params = {
      func: 'rsnx_partners',
    };
    return new Promise((resolve, reject) => {
      BillMgrApi.get('/billmgr', { params })
        .then(data => {
          // commit('SET_PROFILE_INFO', { referals: data.ok });
          commit('SET_REFERALS', data.ok);
          resolve(data);
        })
        .catch(e => reject(e));
    });
  },
  fetchUserParams({ commit }) {
    const params = {
      func: 'usrparam',
      show_metadata: 'on',
      newface: 'on',
    };
    return new Promise((resolve, reject) => {
      BillMgrApi.get('/billmgr', { params })
        .then(data => {
          commit('UPDATE_PROFILE_INFO', { prop: 'usrparamModel', value: data.model });
          if (data.fields) commit('UPDATE_PROFILE_INFO', { prop: 'fields', value: data.fields });
          if (data.list) commit('UPDATE_PROFILE_INFO', { prop: 'list', value: data.list });
          if (data.slist) commit('UPDATE_PROFILE_INFO', { prop: 'slist', value: data.slist });
          resolve(data);
        })
        .catch(e => reject(e));
    });
  },
  fetchPayersInfo({ commit }) {
    // console.log('1212');
    const params = {
      func: 'clientoption',
      show_metadata: 'on',
      newface: 'on',
    };
    return new Promise((resolve, reject) => {
      BillMgrApi.get('/billmgr', { params }).then(data => {
        // console.log(data);
        commit('SET_PAYERS', { id: data.model.profile_id, type: data.model.profile_profiletype });
        // commit('UPDATE_PROFILE_INFO', { prop: 'usrparamModel', value: data.model });
        // if (data.fields) commit('UPDATE_PROFILE_INFO', { prop: 'fields', value: data.fields });
        // if (data.list) commit('UPDATE_PROFILE_INFO', { prop: 'list', value: data.list });
        // if (data.slist) commit('UPDATE_PROFILE_INFO', { prop: 'slist', value: data.slist });
        resolve(data);
      });
      // .catch(e => reject(e));
    });
  },
  fetchAllowedUsers({ commit }) {
    const params = {
      func: 'user',
      newface: 'on',
      show_metadata: 'on',
    };
    return new Promise((resolve, reject) => {
      BillMgrApi.get('/billmgr', { params })
        .then(data => {
          const { elem, metadata } = data;
          if (elem.length) commit('UPDATE_PROFILE_INFO', { prop: 'allowedUsers', value: elem });
          if (metadata.toolbtndata) commit('SET_TOOLS', metadata.toolbtndata);
          resolve(data);
        })
        .catch(e => reject(e));
    });
  },
  fetchUser(ctx, { id, func = 'user.edit' }) {
    //if (!id) throw new Error('Missed required params: "id" in fetchUserInfo');
    const params = {
      //elid: id,
      func,
      show_metadata: 'on',
      newface: 'on',
    };
    if (id) params.elid = id;
    return BillMgrApi.get('/billmgr', { params });
  },
  updateAllowedUser({ dispatch }, { id, data, func = 'user.edit' }) {
    //if (!id || !func) throw new Error('Missed required params: "id" or "func" in updateAllowedUser');
    const params = {
      func,
      out: 'bjson',
      sok: 'ok',
      auth: localStorage.getItem('token'),
      ...data,
    };
    if (id) params.elid = id;
    const formData = formDataParser(params);
    return new Promise((resolve, reject) => {
      BillMgrApi.post('/billmgr', formData)
        .then(data => {
          dispatch('fetchAllowedUsers');
          resolve(data);
        })
        .catch(e => reject(e));
    });
  },
  updateProfileInfo({ dispatch }, { id, data, func }) {
    if (!id || !data)
      throw new Error('Missed required params: "id" or "data" in updateProfileInfo');
    const params = {
      elid: id,
      auth: localStorage.getItem('token'),
      // out: 'bjson',
      clicked_button: 'ok',
      out: 'xjson',
      sok: 'ok',
      func: func ? func : 'user.edit',
      ...data,
    };
    const formData = formDataParser(params);
    return new Promise((resolve, reject) => {
      BillMgrApi.post('/billmgr', formData)
        .then(data => {
          dispatch('setUserInfo', id);
          // console.log('setUserInfo', localStorage.getItem('token'));
          dispatch('fetchUserParams');
          // console.log('fetchUserParams', localStorage.getItem('token'));
          resolve(data);
        })
        .catch(e => reject(e));
    });
  },
  setUserRole({ dispatch }, { id, data }) {
    console.log(data);
    if (!id || !data)
      throw new Error('Missed required params: "id" or "data" in updateProfileInfo');
    const params = new URLSearchParams();
    params.append('elid', id);
    params.append('func', 'usrparam');
    params.append('out', 'xjson');
    params.append('role', data.role);
    params.append('clicked_button', 'ok');
    console.log(params);
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
    };
    return new Promise((resolve, reject) => {
      BillMgrApi.post('/billmgr', params, config)
        .then(data => {
          resolve(data);
        })
        .catch(e => reject(e));
    });
  },
  setAllowedUserRole({ dispatch }, { data }) {
    // console.log(data.referer);
    // if (!id || !data)
    //   throw new Error('Missed required params: "id" or "data" in updateProfileInfo');
    const params = new URLSearchParams();
    params.append('elid', data.id);
    params.append('func', 'user.edit');
    params.append('out', 'xjson');
    params.append('sok', 'ok');
    params.append('role', data.role);
    params.append('clicked_button', 'ok');
    console.log(params);
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        referer: data.referer,
      },
    };
    return new Promise((resolve, reject) => {
      BillMgrApi.post('/billmgr', params, config)
        .then(data => {
          resolve(data);
        })
        .catch(e => reject(e));
    });
  },

  setTotp({ dispatch }, { data, func }) {
    const params = {
      auth: localStorage.getItem('token'),
      out: 'bjson',
      sok: 'ok',
      func: func ? func : 'usrparam',
      ...data,
    };
    const formData = formDataParser(params);
    return new Promise((resolve, reject) => {
      BillMgrApi.post('/billmgr', formData)
        .then(resp => {
          const { ok } = resp;
          if (ok && ok === true) resolve();
          if (ok && ok.v && ok.type === 'form') {
            const payload = qs.parse(ok.v);
            dispatch('fetchUser', payload).then(data => resolve(data));
          } else throw new Error('Something went wrong');
        })
        .catch(e => reject(e));
    });
  },
  sendPhoneNumber({ state }, phoneNumber) {
    const params = {
      func: 'validatephone.start',
      out: 'bjson',
      auth: localStorage.getItem('token'),
      phone: phoneNumber,
      snext: 'ok',
      sok: 'ok',
    };
    const formData = formDataParser(params);
    return new Promise((resolve, reject) => {
      BillMgrApi.post('/billmgr', formData)
        .then(data => {
          resolve(data);
        })
        .catch(err => reject(err));
    });
  },
  sendCode({ state }, code) {
    const params = {
      func: 'validatephone.finish',
      out: 'bjson',
      auth: localStorage.getItem('token'),
      code: code,
      sok: 'ok',
    };
    const formData = formDataParser(params);
    return new Promise((resolve, reject) => {
      BillMgrApi.post('/billmgr', formData)
        .then(data => {
          resolve(data);
        })
        .catch(err => reject(err));
    });
  },
  reset({ commit }) {
    commit('RESET_PROFILE_INFO');
    commit('SET_ACCESS', null);
  },
  setPhoneValidationResult({ commit }) {
    commit('SET_PHONE_VALIDATION_RESULT');
  },
};
