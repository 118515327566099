export default {
  computed: {
    contextMenu() {
      if (!this.current) return [];
      if (!this.tools.edit && !this.tools.delete) return [];
      const arr = [];
      if (this.tools.edit) {
        arr.push({
          key: 'edit',
          tool: this.tools.edit,
          disabled: !this.tools.edit.isEnable(this.current),
        });
      }
      if (this.provider === '2' || this.provider === '3') {
        arr.push({
          key: 'transfer',
          handler: this.linkToTransfer,
        });
      }
      if (this.tools.delete) {
        arr.push({
          key: 'delete',
          color: 'del',
          askSure: true,
          tool: this.tools.delete,
          disabled: !this.tools.delete.isEnable(this.current),
        });
      }
      return [arr];
    },
  },
};
