import { parseCost } from '@/utils/parsers/costParser';
import { parseBoolFromString } from '@/utils/parsers/stringParser';
import { isEmpty } from 'lodash';
import { baseColors } from '@/models/base/baseColors';

export class AllowedUser {
  constructor(args) {
    if (this.isValid(args)) this._init(args);
    else throw new Error('Missed required data in AllowedUser');
  }

  isValid(args) {
    this._isValid = args.account_id && args.id && args.name && args.email && args.enabled;
    return this._isValid;
  }

  _init(args) {
    this.avatar_view = args.avatar_view || '';
    this.default_avatar = args.default_avatar || '';
    this.host = args.host || '';
    this._access = '';
    this._state = '';
    this.role = args.role;
    this._profile = null;
    this.id = args.id;
    this.account_id = args.account_id;
    this.name = args.name;
    this.realname = args.realname;
    this.email = args.email;
    this.access = args.default_access_allow || 'off';
    this.enabled = args.enabled;
    this.state = args.enabled;
  }

  get access() {
    return this._access;
  }
  set access(value) {
    this._access = value;
  }
  set default_access_allow(value) {
    this.access = value;
  }

  get state() {
    return this._state;
  }
  // get roles() {
  //   if (!this.slist || !this.slist.role) return [];
  //   return this.slist.role;
  // }

  set state(value) {
    const states = {
      on: {
        key: 'active',
        color: baseColors.success,
      },
      off: {
        key: 'inactive',
        color: baseColors.error,
      },
      unknown: {
        key: 'unknown',
        color: baseColors.warning,
      },
    };
    this._state = states[value] || states.unknown;
  }

  update(args) {
    for (let key in args) {
      this[key] = args[key];
    }
  }

  setRole(args) {
    this.roles = args;
  }
  // get slist() {
  //   return this._slist;
  // }

  get avatar_src() {
    return this.avatar_view === this.default_avatar ? '' : this.avatar_view;
  }

  get avatar() {
    return this.host && this.avatar_src ? this.host + this.avatar_src : '';
  }
}

export class UserProfile {
  constructor(args) {
    if (this.isValid(args)) this._init(args);
    else throw new Error('Missed required data in UserProfile');
  }

  isValid(args) {
    this._isValid = !!args.id;
    return this._isValid;
  }

  _init(args) {
    this._fields = {};
    this._slist = {};
    this._list = {};
    this._usrparamModel = {};
    this._editModel = {};
    this._avatar_src = '';
    this._allowedUsers = [];
    // this.editModelPrefix = 'user.edit';
    this.editModelPrefix = '';
    // this.usrparamModelPrefix = 'usrparam';
    this.usrparamModelPrefix = '';
    this.id = args.id;
    this.account = args.account;
    this.regdate = new Date(args.account_regdate) || args.account_regdate;
    this.avatar = args.avatar;
    this.default_avatar = args.default_avatar;
    this.host = args.host || '';
    this.avatar_src = args.avatar_src;
    this.balance = parseCost(args.balance) || 0;
    this.currency = args.currency;
    this.currency_id = args.currency_id;
    this.email = args.email;
    this.email_verified = args.email_verified;
    this.hostname = args.hostname;
    this.lang = args.lang || 'ru';
    this.level = args.level;
    this.name = args.name;
    this.need_email_validate = parseBoolFromString(args.need_email_validate);
    this.need_phone_validate = parseBoolFromString(args.need_phone_validate);
    this.phone = args.phone;
    this.realname = args.realname;
    this.timezone = args.timezone;
    this.userid = args.userid;
  }

  update(args) {
    for (let key in args) {
      this[key] = args[key];
    }
  }

  get hasUsrParams() {
    return !isEmpty(this.fields);
  }

  set fields(args) {
    this._fields = {
      ...this._fields,
      ...args,
    };
  }
  get instruction() {
    return this.fields.instruction;
  }

  get telegramId() {
    return this.fields.telegram_id;
  }

  get telegramSupport() {
    return this.fields.telegram_support;
  }

  get fields() {
    return this._fields;
  }

  set list(args) {
    this._list = {
      ...this.list,
      ...args,
    };
  }
  get list() {
    return this._list;
  }

  set slist(args) {
    this._slist = {
      ...this._slist,
      ...args,
    };
  }
  get slist() {
    return this._slist;
  }

  set avatar_view(value) {
    this.avatar_src = value;
  }

  set avatar_src(value) {
    this._avatar_src = value === this.default_avatar ? '' : value;
  }

  get avatar_src() {
    return this._avatar_src;
  }

  get avatarSettings() {
    if (!this.fields || !this.fields.avatar_file_upload) return null;
    const upload = this.fields.avatar_file_upload;
    const view = this.fields.avatar_view || { width: 80, height: 80 };
    return {
      maxsize: parseInt(upload.maxsize),
      name: upload.name,
      width: parseInt(view.width),
      height: parseInt(view.height),
    };
  }

  get timezones() {
    if (!this.slist || !this.slist.timezone) return [];
    return this.slist.timezone;
  }

  get roles() {
    if (!this.slist || !this.slist.role) return [];
    return this.slist.role;
  }

  set editModel(obj) {
    for (let key in obj) {
      this._editModel[key] = obj[key];
    }
  }
  get editModel() {
    return this._editModel;
  }

  set usrparamModel(obj) {
    for (let key in obj) {
      this._usrparamModel[key] = obj[key];
    }
  }
  get usrparamModel() {
    return this._usrparamModel;
  }

  get noticeList() {
    if (!this.list || !this.list.notice_list) return [];
    return this.list.notice_list.reduce((acc, item) => {
      const fields = Object.keys(item).filter(key => key !== 'desc');
      let obj = {
        key: item.desc,
        list: fields.map(key => ({
          key,
          name: `${item.desc}_${key}`,
          trueValue: 'on',
          falseValue: 'off',
          value: this.usrparamModel[`${item.desc}_${key}`] || 'off',
        })),
      };
      acc.push(obj);
      return acc;
    }, []);
  }

  get passwordSettings() {
    if (!this.fields || !this.fields.passwd || !this.fields.old_passwd || !this.fields.confirm)
      return [];
    return [
      { key: 'old', ...this.fields.old_passwd, required: true },
      { key: 'new', ...this.fields.passwd, required: true },
      { key: 'confirm', ...this.fields.confirm, required: true },
    ];
  }

  get accessLimitSettings() {
    if (!this.fields || !this.fields.secureip || !this.fields.atype || !this.slist.atype) return [];
    return [
      {
        key: 'atype',
        ...this.fields.atype,
        type: 'radio',
        options: this.slist.atype,
      },
      {
        key: 'addr',
        ...this.fields.addr,
        type: 'textarea',
        enable: {
          name: 'atype',
          value: 'atallow',
        },
        required: true,
      },
      {
        key: 'secureip',
        ...this.fields.secureip,
        trueValue: 'on',
        falseValue: 'off',
      },
    ];
  }

  get totp() {
    if (
      !this.fields ||
      !this.fields.totp_info ||
      !this.fields.enable_totp ||
      !this.fields.status_totp
    )
      return null;
    const statuses = {
      on: { action: 'off', field: 'disable' },
      off: { action: 'on', field: 'enable' },
    };
    const status = this.fields.status_totp.value || 'off';
    return {
      desc: this.fields.totp_info.label,
      status,
      state: statuses[status],
      enable: this.fields.enable_totp,
      disable: this.fields.disable_totp,
    };
  }

  get confidencialActions() {
    const arr = [];
    if (!this.fields) return arr;
    const { req_export_csv, req_restrict_personaldata, req_remove_personaldata } = this.fields;
    if (req_export_csv) arr.push({ key: 'export', ...req_export_csv });
    if (req_restrict_personaldata) arr.push({ key: 'restrict', ...req_restrict_personaldata });
    if (req_remove_personaldata) arr.push({ key: 'remove', ...req_remove_personaldata });
    return arr;
  }

  get allowedUsers() {
    return this._allowedUsers;
  }
  set allowedUsers(list) {
    this._allowedUsers = list
      .filter(u => u.self !== 'on')
      .map(u => new AllowedUser({ ...u, default_avatar: this.default_avatar, host: this.host }));
  }
}
