export default {
  computed: {
    profile() {
      return this.$store.state.moduleProfile.profile;
    },
  },
  methods: {
    getPreparedFormData(data, profile = this.profile) {
      const obj = {};
      console.log(profile.editModel, profile.usrparamModel);
      // Object.keys(profile.editModel).forEach(key => {
      // obj[`${profile.editModelPrefix}-${key}`] = profile.editModel[key];
      // obj[`${key}`] = profile.editModel[key];
      // });
      Object.keys(profile.usrparamModel).forEach(key => {
        // obj[`${profile.usrparamModelPrefix}-${key}`] = profile.usrparamModel[key];
        obj[`${key}`] = profile.usrparamModel[key];
      });
      for (let key in data) {
        // if (obj.hasOwnProperty(`${profile.editModelPrefix}-${key}`))
        if (obj.hasOwnProperty(`${key}`))
          // obj[`${profile.editModelPrefix}-${key}`] = data[key];
          obj[`${key}`] = data[key];
        // if (obj.hasOwnProperty(`${profile.usrparamModelPrefix}-${key}`))
        // if (obj.hasOwnProperty(`${key}`))
        // obj[`${profile.usrparamModelPrefix}-${key}`] = data[key];
        // obj[`${key}`] = data[key];
      }
      return obj;
    },
    setUserRole(payload) {
      return this.$store.dispatch(
        'moduleProfile/setUserRole',
        {
          id: this.profile.id,
          data: payload,
        },
        {}
      );
    },
    setAllowedUserRole(payload) {
      return this.$store.dispatch(
        'moduleProfile/setAllowedUserRole',
        {
          data: payload,
        },
        {}
      );
    },
    updateProfileInfo(payload, func = 'user.edit') {
      // console.log(this.profile.id, 'this.profile.id');
      return this.$store.dispatch('moduleProfile/updateProfileInfo', {
        id: this.profile.id,
        data: payload,
        func,
      });
    },
    showResultModal(text) {
      this.$modals.open({
        name: 'Result',
        text,
        size: 'small',
        footer: {
          centered: true,
          confirm: {
            props: { title: this.$t('ok') },
            on: {
              click: () => this.$modals.close(),
            },
          },
        },
      });
    },
  },
};
