<template>
  <div class="radio-block">
    <div v-if="label || $slots.default" class="radio-block__label standart-title">
      <slot>{{ label }}</slot>
      <span v-if="$attrs.required || config.required" class="radio-block__label-asterisk">*</span>
      <plain-button
        v-if="hint"
        v-tooltip="{ content: hint, placement: 'top-start', container: false }"
        icon="help"
        size="small"
        color="dim"
        tabindex="-1"
        class="radio-block__hint"
      />
    </div>
    <div v-tooltip="config.disabled ? tooltipSettings : null" class="radio-block__fields">
      <base-radio
        v-for="opt in options"
        :key="opt.k"
        v-model="internalValue"
        :value="opt.k"
        :disabled="config.disabled"
        class="radio-block__radio"
      >
        {{ opt.v }}
      </base-radio>
    </div>
    <div
      v-if="isQuality"
      v-tooltip="config.disabled ? tooltipSettings : null"
      class="radio-block__like"
    >
      <label class="typo__label small-text note-color">
        {{ $t(`${intname}.dislike`) }}
      </label>
      <label class="typo__label small-text note-color"> {{ $t(`${intname}.like`) }} </label>
    </div>
  </div>
</template>

<script>
import BaseRadio from '@/components/Checkboxes/BaseRadio.vue';
import RadioComponent from '@/models/base/components/radio';
export default {
  name: 'RadioBlock',
  components: { BaseRadio },
  props: {
    config: {
      type: Object,
      required: true,
      validator: obj => obj instanceof RadioComponent || typeof obj.value !== 'undefined',
    },
    label: {
      type: String,
      default: null,
    },
    intname: {
      type: String,
      default: null,
    },
    cost: {
      type: Number,
      default: 0,
    },
    period: {
      type: [Number, String],
      default: 1,
      validator: val => [1, 3, 6, 12].includes(parseInt(val)),
    },
    periodKey: {
      type: String,
      default: null,
    },
    hint: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      internalValue: this.config.value,
    };
  },
  computed: {
    tooltipSettings() {
      return {
        content: this.$t('restrictclientchange.on'),
        placement: 'top-start',
      };
    },
    isQuality() {
      return ['slist_csat_points', 'slist_ces_points', 'slist_nps_points'].includes(this.intname);
    },
    options() {
      return this.config.options;
    },
  },
  watch: {
    config: function (newconfig) {
      if (this.internalValue != newconfig.value) this.internalValue = newconfig.value;
    },
    internalValue: function (value) {
      this.onChange(value);
    },
  },
  methods: {
    onChange(value) {
      this.config.value = value;
      this.internalValue = this.config.value;
      this.$emit('change', {
        value: this.config.value,
        sum: this.cost,
      });
    },
  },
};
</script>
<i18n>
{
  "ru": {
    "slist_csat_points": {
      "like": "Очень доволен",
      "dislike": "Совсем недоволен"
    },
    "slist_ces_points": {
      "like": "Всё сделали",
      "dislike": "Отнеслись формально"
    },
    "slist_nps_points": {
      "like": "Всегда рекомендую",
      "dislike": "Совсем не готов"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.radio-block {
  &__label {
    flexy(flex-start, baseline);
    margin-bottom: 10px;
    color: var(--text);
    transition: color $anim-base-duration ease, opacity $anim-base-duration ease;

    ^[0]--disabled &,
    ^[0]--readonly & {
      opacity: 0.2;
    }

    &-asterisk {
      color: $error-color;
      margin-left: 0.125rem;
    }
    &-end {
      margin-right: 0;
      margin-left: auto;
    }
  }

  &__hint {
    margin-left: 0.25rem!important;
    align-self: center;
  }

  &__like {
    flexy(space-between);
    margin-top: 0.5rem
  }
  &__fields {
    flexy(flex-start, center);
  }

  &__radio {
    & + & {
      //margin-left: 1.5rem;
      margin-left: 1.2rem;
    }
  }
}
</style>
