import BaseConfigurator from '@/models/base/BaseConfigurator';

export default {
  data() {
    return {
      configuratorClass: BaseConfigurator,
    };
  },
  computed: {},
  methods: {
    getPayersData(item) {
      const payload = item.id ? { elid: item.id } : {};
      let params = {
        func: item.tool.func,
        ...payload,
      };

      if (this.current.toolParams) Object.assign(params, this.current.toolParams);
      if (item.toolParams) Object.assign(params, item.toolParams);

      this.$store.dispatch('fetchBillMgrToolAction', params).then(async data => {
        const { fields, hidefields, model, slist, pages } = data;
        const config = new this.configuratorClass({
          customfields: fields,
          hidefields: hidefields || [],
          model: model || {},
          slist: slist || {},
          pages: pages || [],
        });
        this.payersData = data.fields;
        // console.log('data', this.payersData);
      });
    },
  },
};
