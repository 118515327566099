<template>
  <div class="signup">
    <h1 class="page-title">
      {{ $t('title') }}
    </h1>
    <transition name="fade" mode="out-in" @after-enter="setFocus()">
      <base-loader v-if="isLoading" class="signup__loader" />
      <div>
        <page-block :title="$t('tab.account')" size="bigger">
          <main-card class="reregistration__card"> Какой-то текст </main-card>
        </page-block>
      </div>
    </transition>
  </div>
</template>

<script>
import BaseInput from '@/components/BaseInput/BaseInput';
import BaseSelect from '@/components/Select/BaseSelect';
import { isObject } from 'lodash';
import setFocus from '@/mixins/setFocus';
import generatePassword from '@/mixins/generatePassword';
import Policy from '@/components/Policy/Policy';
import { currentProvider, findAllByKey, IS_PROD } from '@/layouts/LoginPage/mixins/currentProvider';
import BaseButtonsGroup from '@/components/Buttons/BaseButtonsGroup.vue';
import reRegistrationSuccess from '@/layouts/Profile/mixins/reRegistrationSuccess';

export default {
  name: 'TicketRequest',
  components: {},
  mixins: [setFocus, reRegistrationSuccess, generatePassword],
  data() {
    return {
      typeValue: 'new',
      statusValue: 'physical',
      country: 'Российская федерация',
      state: '',
      partner: '',
      isLoading: true,
      isSending: false,
      error: '',
      fieldsNew: [
        {
          name: 'email',
          type: 'email',
          invalid: false,
          value: '',
        },
        {
          name: 'passwd',
          type: 'password',
          invalid: false,
          value: '',
          tooltip: this.$t('fields.new.passwd.tooltip'),
        },
        {
          name: 'realname',
          type: 'text',
          invalid: false,
          value: '',
        },
        {
          name: 'phone',
          type: 'tel',
          invalid: false,
          value: '',
        },
      ],
      fieldsAlready: [
        {
          name: 'id',
          type: 'text',
          invalid: false,
          value: '',
        },
      ],
      typeList: [
        {
          title: 'Новый аккаунт',
          value: 'new',
        },
        {
          title: 'Аккаунт уже существует',
          value: 'already',
        },
      ],
      statusList: [
        {
          title: 'Физическое лицо',
          value: 'physical',
        },
        {
          title: 'ИП',
          value: 'individual',
        },
        {
          title: 'Юридическое лицо',
          value: 'legal',
        },
      ],
      policy: {},
      countries: [],
      states: [],
    };
  },
  computed: {
    isValid() {
      const validatedFields =
        !this.fieldsNew[5].value && this.filtredState.length === 0
          ? this.fieldsNew.slice(0, 5)
          : this.fieldsNew;
      // console.log(validatedFields);
      // return this.fields.every(i => !i.required || (i.required && i.value));
      return validatedFields.every(i => !i.required || (i.required && i.value));
    },
    currentField() {
      return findAllByKey(currentProvider, this.currentHost).toString();
    },
    currentHost() {
      return IS_PROD ? localStorage.getItem('host') : this.$store.state.moduleApp.host;
    },
    currentCountry() {
      console.log('currentCountry', this.fieldsNew);
      return this.fieldsNew.find(x => x.name === 'country').value.k;
    },
    provider() {
      if (IS_PROD) {
        if (this.currentHost.includes('infobox')) {
          return '5';
        } else if (this.currentHost.includes('zenon')) {
          return '4';
        } else if (this.currentHost.includes('astracloud')) {
          return '6';
        } else if (this.currentHost.includes('rusonyx')) {
          return '3';
        } else if (this.currentHost.includes('caravan')) {
          return '2';
        } else return '3';
      } else {
        return this.$store.state.moduleProviders.current
          ? this.$store.state.moduleProviders.current
          : null;
      }
    },

    text() {
      return `Компания ${
        this.provider === '4' ? 'ООО "ЗЕНОН Н.С.П."' : 'ООО "Национальные Телекоммуникации"'
      }
       // находится в процессе  <a href="${
         this.provider === '4'
           ? 'https://www.rusonyx.ru/blog/post/reorganizaciya-kompaniy-rusoniks/'
           : 'https://www.rusonyx.ru/blog/post/reorganizaciya-kompaniy-rusoniks/'
       }" target="_blank">реорганизации</a>
       в форме присоединения к ООО "Русоникс".`;
    },
    filtredState() {
      return this.states.filter(x => x.depend === this.currentCountry);
    },
    // partner() {
    // return this.$route.query.value;
    // },
  },
  mounted() {
    this.fetch();
    this.partner =
      this.$route.query && this.$route.query.partner ? this.$route.query.partner : null;
    // console.log(this.$route.query.partner);
  },
  methods: {
    showPassword(item) {
      if (item.type === 'password') {
        item.type = 'text';
      } else {
        item.type = 'password';
      }
    },
    onTypeChange(value) {
      // console.log('periodValue', value);
      this.typeValue = value;
      if (value) {
        // this.internalValue = this.getNormalizedInterval(this.standartPeriods[this.typeValue]);
        // this.$emit('change', this.internalValue);
      }
    },
    onStatusChange(value) {
      // console.log('periodValue', value);
      this.statusValue = value;
      if (value === 'legal') {
        let field = this.fieldsNew.find(x => x.name === 'realname');
        if (this.fieldsNew && field) field.name = 'realnameLegal';
        // this.internalValue = this.getNormalizedInterval(this.standartPeriods[this.typeValue]);
        // this.$emit('change', this.internalValue);
      } else if (value === 'physical' || value === 'individual') {
        let field = this.fieldsNew.find(x => x.name === 'realnameLegal');
        if (this.fieldsNew && field) field.name = 'realname';
      }
    },
    onChangeCountry() {
      this.fieldsNew[5].value = undefined;
    },
    link() {
      //https://my.rusonyx.ru/#/login/reg
      // return console.log('link');
      window.open('https://my.rusonyx.ru/#/login/reg');
    },
    onChange(item, e) {
      if (item.name === 'realname' && this.statusValue === 'individual') {
        const prefix = 'ИП_';
        if (!e.startsWith(prefix)) {
          let name = '';
          name = prefix + e;
          this.fieldsNew.find(x => x.name === 'realname').value = name;
        }
      } else if (
        (item.name === 'realname' || item.name === 'realnameLegal') &&
        this.statusValue !== 'individual'
      ) {
        const prefix = 'ИП_';
        if (e.startsWith(prefix)) {
          if (this.statusValue === 'physical') {
            let name = this.fieldsNew.find(x => x.name === 'realname').value;
            this.fieldsNew.find(x => x.name === 'realname').value = name.replace(prefix, '');
          } else {
            let name = this.fieldsNew.find(x => x.name === 'realnameLegal').value;
            this.fieldsNew.find(x => x.name === 'realnameLegal').value = name.replace(prefix, '');
          }
        }
      } else if (item.name === 'phone') {
        let res = '';
        this.fieldsNew.find(x => x.name === 'phone').value = e.replace('+', '+7');
        let tel = this.fieldsNew.find(x => x.name === 'phone').value;
        if (tel.substr(0, 1) === '8') {
          res = '+7' + tel.substr(1);
          this.fieldsNew.find(x => x.name === 'phone').value = res;
        }
      }
      const { value } = e;
      if (item === 'policy') {
        this.policy.value = value;
      }
    },
    regUser() {
      if (!this.isValid) return false;
      this.isSending = true;
      const formData = this.fieldsNew.reduce((acc, item) => {
        if (isObject(item.value) && item.value.k) acc[item.name] = item.value.k;
        else if (item.value) acc[item.name] = item.value;
        return acc;
      }, {});
      // вытаскиваем поле полльзовательского соглашения, если оно включено, в противном - пусто
      const field =
        Object.keys(this.policy).length !== 0
          ? Object.fromEntries([[this.currentField, this.policy.value]])
          : null;
      const payload = {
        ...formData,
        sok: 'ok',
        func: 'register',
        ...field,
      };
      if (this.partner) payload.partner = this.partner;
      this.$store
        .dispatch('send', payload)
        .then(() => {
          this.showSuccess();
          this.$gtm.trackEvent({
            event: '_event_registration', // Event type [default = 'interaction'] (Optional)
            category: 'Login',
            action: 'click',
            label: 'Authenticator',
            value: 5000,
            noninteraction: false, // Optional
          });
        })
        .catch(e => {
          this.error = e.msg;
          this.fieldsNew.forEach(i => {
            if (i.name === e.object) i.invalid = true;
            else i.invalid = false;
          });
        })
        .finally(() => (this.isSending = false));
    },
    // gtm() {
    //   this.$gtm.trackEvent({
    //     event: '_event_registration', // Event type [default = 'interaction'] (Optional)
    //     category: 'Login',
    //     action: 'click',
    //     label: 'Authenticator',
    //     value: 5000,
    //     noninteraction: false, // Optional
    //   });
    // },
    fetch() {
      this.isLoading = true;
      this.$store
        .dispatch('fetch', 'register')
        .then(data => {
          const { required, slist } = data;
          // выгружаем пользовательское соглашение
          const policy = data.customfields ? data.customfields[this.currentField] : null;
          this.policy = { ...policy };
          if (slist && slist.country) this.addCountryField(slist.country);
          if (slist && slist.state) this.addStateField(slist.state);
          if (required) this.setRequiredFields(required);
        })
        .finally(() => (this.isLoading = false));
    },
    setRequiredFields(obj) {
      this.fieldsNew.forEach(i => {
        if (obj[i.name] && obj[i.name] === 'true') i.required = true;
      });
    },
    addCountryField(list) {
      const data = list.reduce((acc, item) => {
        if (!acc.list) acc.list = [];
        if (item.k !== 'null') acc.list.push(item);
        if (item.v === this.$t('fields.new.country.default')) acc.value = item;
        return acc;
      }, {});
      this.countries = data.list;
      this.fieldsNew.push({
        name: 'country',
        type: 'select',
        value: data.value,
      });
    },
    addStateField(list) {
      const data = list.reduce((acc, item) => {
        if (!acc.list) acc.list = [];
        if (item.k !== 'null') acc.list.push(item);
        if (item.v === this.$t('fields.new.state.default')) acc.value = item;
        return acc;
      }, {});
      this.states = data.list;
      this.fieldsNew.push({
        name: 'state',
        type: 'select',
        value: data.value,
      });
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "title": "Переоформление",
    "tab": {
      "registration": "Регистрация нового аккаунта",
      "account": "Существующий аккаунт"
    },
    "doc": {
      "text_1": "Переоформление договора на другое лицо (Смена плательщика)",
      "text_2": "Процедуру переоформления договора следует выполнять в ситуациях, когда требуется изменить плательщика за услуги хостинга/домена на нового плательщика. В целях сохранения истории платежей это потребует регистрации нового аккаунта и переноса всех услуг на него.",
      "text_3": "Обратите, пожалуйста, внимание, что речь в данной статье идёт только о переоформлении договора, заключенного с ООО «Астра Облако». Если Вы хотите передать права на домен у регистратора доменного имени, то Вам необходимо выполнить процедуру передачи прав на домен.",
      "text_4": "Исключения:",
      "text_5": "* Если оплаты на Вашем аккаунте еще не производилось, то можно полностью изменить данные плательщика, в том числе тип - с физического на юридическое лицо или наоборот.",
      "text_6": "* Если у юридического лица произошла реорганизация без смены ИНН или изменилось наименование, регистрировать новую учетную запись не нужно. Достаточно оповестить нас об этом и приложить к письму копию передаточного акта или уведомления о смене наименования юридического лица, и мы актуализируем реквизиты Вашей учетной записи."
    },

    "fields": {
      "new": {
      "placeholder": "Введите название",
      "realname": {
        "label": "ФИО"
      },
        "realnameLegal": {
        "label": "Наименование"
      },
      "email": {
        "label": "Email"
      },
      "phone": {
        "label": "Телефон"
      },
      "passwd": {
        "label": "Пароль",
        "tooltip": "Сгенерировать надежный пароль"
      },
      "country": {
        "label": "Страна",
        "note": "Укажите страну правильно! Выбор страны влияет на ставку налогообложения при оплате",
        "default": "Российская Федерация"
      },
      "state":{
        "label": "Регион",
        "default": " "
      }
      },
      "already": {
        "placeholder": "Введите название",
        "id": {
          "label": "id аккаунта"
        }
      }
    },
    "submit": "Зарегистрировать",
    "submitR": "Зарегистрироваться в Русоникс",
    "success": {
      "title": "Подтвердите email",
      "text": "Запрос успешно отправлен, для окончания регистрации перейдите по ссылке в письме, отправленном на указанный email.",
      "ok": "Хорошо"
    }
  }
}
</i18n>

<style lang="stylus" scoped>

.reregistration{
  &__card{
    max-width: 545px;
  }
  &__new{
    margin-top: 1.25rem;
  }
  &__new-info{
    margin-top: 1.25rem;
    margin-left: 40px;
    max-width: 50%;
  }
}
.signup {
  &__field {
    & + & {
      margin-top: 1.25rem;
    }
  }
  &__footer {
    margin-top: 2.5rem;
    text-align: center;
  }
  &__submit {
    min-width: 165px;
  }
  &__error {
    margin-top: 1.25rem;
  }
}
</style>
